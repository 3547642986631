<template>
    <section class="viviendas-solicitudes">
        <titulo2 titulo="Solicitudes de residentes" @inputSearch="buscador"/>
         <!-- tabla -->
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 206px)">
            <div class="col-12">
                <el-table :data="solicitudes" height="calc(100vh - 215px)" style="width: 100%">
                    <el-table-column prop="tipo" width="60">
                        <template slot-scope="scope">
                            <div class="like-img bg1 br-50 d-middle-center f-20 mx-auto">
                                <i class="icon-account-outline op-05" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="nombre" label="Residente" > 
                      <template slot-scope="scope">
                          <div @click="accion(scope.row)">
                            {{scope.row.nombre}} {{scope.row.apellido}} 
                          </div>
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="nombre_vivienda" label="Vivienda" > </el-table-column>
                    <el-table-column sortable prop="fecha_solicitud" label="Registro" width="130"> 
                      <template slot-scope="scope">
                        {{ formatearFecha(scope.row.fecha_solicitud) }}
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="telefono" label="Celular" width="130"> </el-table-column>
                    <el-table-column prop="tipo" label="Acción" width="230" :index="5">
                        <template slot-scope="scope">
                            <div class="row acciones">
                                <div class="col-auto pr-1">
                                    <button class="btn btn-general br-20 py-1 f-14 f-300" @click="modalAceptarSolicitud(scope.row)">Aceptar</button>
                                </div>
                                <div class="col-auto pl-1">
                                    <button class="btn bg-f5 text-86 border br-20 py-1 f-14 f-300" @click="modalDenegarSolicitud(scope.row.id)">Denegar</button>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <div class="row" slot="empty">
                        <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120">
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </div>
                    <infinite-loading
                    slot="append"
                    force-use-infinite-wrapper=".el-table__body-wrapper"
                    @infinite="listar"
                    />
                </el-table>
                <!-- <div class="col-12">
                    <infinite-loading @infinite="listar">
                        <div slot="spinner">Consultando...</div>
                        <div slot="no-more">No hay más solicitudes</div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                </div> -->
            </div>
            
        </div>
        <!-- partials -->
        <modal-aceptar-residente ref="abrirModalAceptarResidente" @cargar="aceptarResidente"/>
        <modal ref="abrirDenegarSolicitud" titulo="Denegar solicitud" adicional="Denegar" @adicional="rechazarResidente" >
            <p class="f-15 mb-4 text-center">¿Desea denegar el acceso a este usuario?</p>
            <el-input type="textarea" v-model="comentario" placeholder="Por favor escriba una justificación" show-word-limit maxlength="300" class="w-100" :rows="4"/>
        </modal>
    </section>
</template>
<script>
import Usuarios from '~/services/usuarios'
import InfiniteLoading from 'vue-infinite-loading';


export default {
    components:{
        modalAceptarResidente: () => import('./partials/modalAceptarResidente'),
        InfiniteLoading
    },
    data(){
        return{
            page: 1,
            search: null,
            solicitudes:[],
            userSelected: null,
            comentario: null,
        }
    },
    methods:{
        verResidente(){
            /* this.$router.push({ name: 'viviendas.solicitudes.ver' }) */
        },
        modalAceptarSolicitud(usuario){
            const params = {
                vivienda : usuario.nombre_vivienda,
                residente : usuario.residente,
                propietario : usuario.propietario,
                idVivienda : usuario.id_vivienda,
                idUser: usuario.id
            }

            this.$refs.abrirModalAceptarResidente.toggle(params)
        },
        modalDenegarSolicitud(idUser){
            this.comentario = null
            this.userSelected = idUser
            this.$refs.abrirDenegarSolicitud.toggle()
        },
        ver(kAction){
            switch (kAction) {
                case 1:
                    this.$router.push({ name: 'viviendas.solicitudes.ver' })
                break;
                case 2:
                    this.$refs.abrirModalAceptarResidente.toggle()
                break;
                
            }
        },
        
        async listar($state,reload){
          try {

            const state = $state  

            const params={
                page: this.page,
              search : this.search
            }
            const {data} = await Usuarios.solicitudes(params)

            if(data.data.data.length){
              this.page += 1;
              this.search || reload ? this.solicitudes = data.data.data : this.solicitudes.push(...data.data.data)
              if(typeof state !== 'undefined'){
                state.loaded();
              }
            }else{
              if(typeof state !== 'undefined'){
                state.complete();
              }

            }
          } catch (e){
              this.errorCatch(e)
          }
        },
        accion(row){
  
         this.$router.push({ name: 'viviendas.solicitudes.ver', params:{id_residente: row.id} })
        },
        async aceptarResidente(){
            this.page = 1
            this.solicitudes = []
            this.userSelected = null
            this.listar()
            this.$emit('refrescar')     
        },
        async rechazarResidente(){
            try {

                if(!this.comentario) return this.notificacion('','Campo justificación no valido','warning')

                let params = {
                    id_usuario: this.userSelected,
                    comentario: this.comentario,
                }

                const { data } = await Usuarios.rechazarResidente(params)

                this.notificacion('','Residente rechazado correctamente','success')
                this.$refs.abrirDenegarSolicitud.toggle()

                this.page = 1
                this.solicitudes = []
                this.listar()
                this.$emit('refrescar')     
                
                this.userSelected = null
            } catch (error) {
                this.errorCatch(error)
            }
        }, 

        buscador(search){
            this.search = search
            this.page = 1
            this.solicitudes = []
            this.listar()
        }
    }
}
</script>
<style lang="scss" scoped>
.viviendas-solicitudes{
   .acciones{
       .btn{
           min-width: 90px;
           height: 28px;
       }
   }
}
</style>
